import { FC } from 'react';
import { usePageTheme } from '~/app/components/ItemPage/ItemPageEdit/addons/theme/PageThemeContext';

import Text from '~/app/components/Text';
import { toRgba } from '~/app/lib/utils/color';
import prettyWrap from '~/app/lib/utils/prettyWrap';

const IS_LONG_THRESHOLD = 22;

export const ArtistTitle: FC<{ text: string; padding?: string }> = ({
  text,
  children,
  ...textProps
}) => {
  const textIsLong = text!.length > IS_LONG_THRESHOLD;
  const minFontSize = textIsLong ? '3.57rem' : '4.2rem';
  const maxFontSize = textIsLong ? '4.6rem' : '4.9rem';
  const baseSize = textIsLong ? '5vw' : '7vw';
  const isAllCaps = text.toUpperCase() === text;
  const pageTheme = usePageTheme();

  return (
    <Text
      size={baseSize}
      minSize={minFontSize}
      maxSize={maxFontSize}
      maxWidth="14em"
      isCentered
      {...textProps}
    >
      <Text
        testId="title"
        tag="h1"
        bold
        centered
        size="inherit"
        lineClamp={2}
        lineHeight={1.05}
        padding="0 1rem 0.1em"
        shadow={`${toRgba(pageTheme.backgroundColor, 0.1)} 0 0 .05em,${toRgba(
          pageTheme.backgroundColor,
          0.2
        )} 0 .01em .1em`}
        opacity={0.9}
        style={{
          marginTop: '-.2em',
          marginBottom: isAllCaps ? '-.05em' : '-0.02em',
        }}
      >
        {prettyWrap(text)}
      </Text>
      {children}
    </Text>
  );
};
